import { render, staticRenderFns } from "./dependents.vue?vue&type=template&id=e0b225f0&scoped=true&"
import script from "./dependents.vue?vue&type=script&lang=js&"
export * from "./dependents.vue?vue&type=script&lang=js&"
import style0 from "./dependents.vue?vue&type=style&index=0&id=e0b225f0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0b225f0",
  null
  
)

export default component.exports